import React from "react"
import moment from "moment"
import "moment/locale/es"
import styles from "../styles/experiences-page-slide.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

moment.locale("es")

const ExperiencesPageSlide = ({
  gatsbyImage,
  text,
  title,
  date,
  location,
  isMobile,
  buttonText,
  pageFormTitle,
  pageFormText,
  setShowExperienceModal,
  setExperienceFormTitle,
  setExperienceFormText,
}) => {
  const image = getImage(gatsbyImage)

  const displayModal = () => {
    setShowExperienceModal(true)
    // setExperienceFormTitle(pageFormTitle)
    // setExperienceFormText(pageFormText)
  }

  return (
    <div className={styles.experiencesPageSlideContainer} id="inicio">
      <GatsbyImage
        image={image}
        alt={title}
        className={styles.experiencesPageSlidePicture}
      />
      <div className={styles.experiencesPageSlideHeader}>
        {isMobile ? (
          <>
            <div className={styles.experiencesPageSlideHeaderTitle}>
              {location}
            </div>
            <div className={styles.experiencesPageSlideHeaderTitle}>
              {date ? moment(date).format("D [de] MMMM [de] YYYY, h:mm a") : ""}
            </div>
          </>
        ) : (
          <>
            <div className={styles.experiencesPageSlideHeaderTitle}>
              {date ? moment(date).format("D [de] MMMM [de] YYYY, h:mm a") : ""}
            </div>
            <div className={styles.experiencesPageSlideHeaderTitle}>
              {location}
            </div>
          </>
        )}
        <button
          className={styles.experiencesPageSlideHeaderButton}
          onClick={displayModal}
        >
          {buttonText}
        </button>
      </div>
      <div className={styles.experiencesPageSlideTitleAndTextContainer}>
        <div className={styles.experiencesPageSlideTitleContainer}>
          <div
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <div className={styles.experiencesPageSlideTitle}>{title}</div>
          </div>
        </div>
        <div className={styles.experiencesPageSlideTextContainer}>
          <div
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <div className={styles.experiencesPageSlideText}>{text}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExperiencesPageSlide

import React, { useState, useEffect } from "react"
import { useRecoilState } from "recoil"
import loadable from "@loadable/component"
import { graphql, navigate } from "gatsby"
import { IsMobileDevice } from "../utils/functions"
import ExperiencesPageSlide from "../components/experiences-page-slide"
import BocadoHomeExperiences from "../components/bocado-home-experiences"
import BocadoModal from "../components/bocado-modal"
import BocadoCart from "../components/bocado-cart"
import Footer from "../components/footer.jsx"
import Seo from "../components/seo"
import { isMobileAtom, showCartDrawerAtom } from "../recoil/atoms.js"
import styles from "../styles/experiences-page.module.scss"
import { experienceLinkGenerator } from "../utils/link-generator"

const Experiences = ({ data, location }) => {
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom)
  const [showNewsletterModal, setShowNewsletterModal] = useState(false)
  const [showExperienceModal, setShowExperienceModal] = useState(true)
  const [showFooterSuccessModal, setShowFooterSuccessModal] = useState(false)
  const [showCartDrawer, setShowCartDrawer] = useRecoilState(showCartDrawerAtom)

  // console.log("DATAAAA", data.experiencias)

  // const [experienceFormTitle, setExperienceFormTitle] = useState("")
  // const [experienceFormText, setExperienceFormText] = useState("")

  const Menu = loadable(() => import("../components/menu.jsx"))

  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    if (!local && !session)
      navigate("/agegate", {
        state: {
          newPath: experienceLinkGenerator(data.experiencias.pageTitle),
        },
      })
  }, [])

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    )
  }, [])

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  // useEffect(() => {
  //   setExperienceFormTitle(data.experiencias.pageFormTitle)
  //   setExperienceFormText(data.experiencias.pageFormText)
  // }, [])

  return (
    <div className={styles.experiencesContainer}>
      <Seo title="Stella Artois" />
      {showNewsletterModal && (
        <BocadoModal
          type="newsletter"
          isMobile={isMobile}
          setShowModal={setShowNewsletterModal}
          title="BIENVENIDO A BOCADO CLUB"
          subtitle="Registrate para poder participar de los premios y poder disfrutar de los beneficios. Enterate de las últimas novedades."
        />
      )}
      {showExperienceModal && (
        <BocadoModal
          type="experience"
          isMobile={isMobile}
          setShowModal={setShowExperienceModal}
          // title={experienceFormTitle}
          // subtitle={experienceFormText}
          pageTitle={data.experiencias.pageTitle}
          title={data.experiencias.pageFormTitle}
          subtitle={data.experiencias.pageFormText}
          bases={data.experiencias.bases}
          provinces={data.experiencias.provinces}
          question={data.experiencias.question}
          answer={data.experiencias.answer}
          scriptTitle={data.experiencias.scriptTitle}
          scriptInterest={data.experiencias.scriptInterest}
        />
      )}
      {showFooterSuccessModal && (
        <BocadoModal
          isMobile={isMobile}
          setShowModal={setShowFooterSuccessModal}
          onlySuccess={true}
        />
      )}
      <BocadoCart
        showCartDrawer={showCartDrawer}
        setShowCartDrawer={setShowCartDrawer}
      />
      <Menu
        isMobile={isMobile}
        setShowNewsletterModal={setShowNewsletterModal}
        setShowCartDrawer={setShowCartDrawer}
      />
      <ExperiencesPageSlide
        title={data.experiencias.pageTitle}
        text={data.experiencias.pageText}
        date={data.experiencias.date}
        location={data.experiencias.location}
        gatsbyImage={data.experiencias.experiences_page_image}
        isMobile={isMobile}
        buttonText={data.experiencias.cardButtonText}
        pageFormTitle={data.experiencias.pageFormTitle}
        pageFormText={data.experiencias.pageFormText}
        setShowExperienceModal={setShowExperienceModal}
        // setExperienceFormTitle={setExperienceFormTitle}
        // setExperienceFormText={setExperienceFormText}
      />
      <BocadoHomeExperiences
        cardsArray={data.allExperiencias.nodes}
        homePage={false}
        isMobile={isMobile}
        // setShowExperienceModal={setShowExperienceModal}
        // setExperienceFormTitle={setExperienceFormTitle}
        // setExperienceFormText={setExperienceFormText}
        mainComponent={false}
      />
      <Footer
        link={experienceLinkGenerator(data.experiencias.pageTitle) + "#inicio"}
        page={"experiencias"}
        isMobile={isMobile}
        setShowModal={setShowFooterSuccessModal}
      />
    </div>
  )
}

export default Experiences

export const data = graphql`
  query ExperiencesIndividualPages($pageTitle: String) {
    experiencias(pageTitle: { eq: $pageTitle }) {
      id
      pageTitle
      pageText
      date
      location
      provinces
      question
      answer
      pageFormTitle
      pageFormText
      scriptTitle
      scriptInterest
      bases
      provinces
      question
      answer
      cardButtonText
      experiences_page_image {
        childImageSharp {
          gatsbyImageData(height: 1080, quality: 70, formats: [AUTO])
        }
      }
    }
    allExperiencias(
      filter: { cardActive: { eq: true }, pageTitle: { ne: $pageTitle } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        cardButtonText
        cardOverline
        cardText
        cardTitle
        date
        pageActive
        pageTitle
        pageFormTitle
        pageFormText
        bases
        provinces
        question
        answer
        experiences_card_image {
          childImageSharp {
            gatsbyImageData(height: 1080, quality: 70, formats: [AUTO])
          }
        }
      }
    }
  }
`
